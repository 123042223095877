import React, { useState } from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

// components
import Image from './components/Image'
import Triangle from './components/Triangle'
import Content from './components/Content'

const CarouselProfile = styled(({ data, className }) => {
  const [position, setPosition] = useState(0)

  const handleClick = key => {
    setPosition(key)
  }

  return (
    <div className={`${className} bg-cerulean-blue position-relative`}>
      <div className="d-lg-none">
        <div className="container">
          <span className="h6">{data.heading}</span>
        </div>
      </div>
      <div className="d-md-flex justify-content-between">
        <div className="image-wrapper position-relative">
          <Triangle />
          <Image
            fluid={
              data.carouselprofile[position].image.imageFile.childImageSharp
                .fluid
            }
            position={position}
            alt={
              data.carouselprofile[position].name
                ? data.carouselprofile[position].name
                : data.carouselprofile[position].image.altText
            }
          />
        </div>
        <Content data={data} position={position} onClick={handleClick} />
      </div>
    </div>
  )
})`
  .h6 {
    color: #fff;
    display: block;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 134px;

    @media (max-width: 767px) {
      padding: 40px 15px 56px;
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      padding-left: 106px;
    }
  }

  @media (min-width: 768px) {
    margin-left: 130px;
    margin-top: 40px;
    padding-top: 70px;
    padding-right: 25px;
  }

  @media (min-width: 1440px) {
    display: flex;
    padding-right: 0;
  }

  .image-wrapper {
    height: 263px;
    margin-right: 24px;
    max-width: calc(100% - 24px);
    margin-bottom: 40px;
    top: 0;
    width: 100%;

    @media (min-width: 365px) {
      height: 350px;
      //margin: 0 auto 40px;
      //max-width: 510px;
    }

    @media (min-width: 768px) {
      height: 419px;
      margin-left: -130px;
      margin-bottom: 0;
      max-width: 516px;
      top: -70px;
      width: 344px;
    }

    @media (min-width: 800px) {
      height: 600px;
      max-width: none;
      width: 65%;
    }

    @media (min-width: 1440px) {
      height: 616px;
      max-width: 866px;
      min-width: 866px;
      width: 100%;
    }
  }
`

CarouselProfile.propTypes = {
  data: object.isRequired
}

CarouselProfile.defaultProps = {
  data: {}
}

export default CarouselProfile
