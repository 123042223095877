import React, { useState, useEffect, useRef } from 'react'
import { array } from 'prop-types'
import Slider from 'react-slick'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// modules
import { useWindowDimensions } from '@modules/useWindowDimensions'
import sliderSettings from './modules/constants'

// utils
import colors from '@styles/js/colors'

const EXTRAWIDTH = 60
const BOXSPACING = 32

const Testimonials = styled(({ className, data }) => {
  const { width: windowSize } = useWindowDimensions()

  const refWhy = useRef(null)
  const refMain = useRef(null)

  const [whyWrapperWidth, setWhyWrapperWidth] = useState({})
  const [mainWrapperWidth, setMainWrapperWidth] = useState({})
  const [mainGap, setMainGap] = useState({})

  useEffect(() => {
    const articlesCurrentWidth = refWhy.current.clientWidth

    const mainPosition = (whyWrapperWidth - refMain.current.clientWidth) / 2
    const mainCurrentWidth = refMain.current.clientWidth + mainPosition

    setWhyWrapperWidth(articlesCurrentWidth)

    setMainWrapperWidth(mainCurrentWidth)
    setMainGap(mainPosition)
  }, [windowSize, whyWrapperWidth, refMain])

  return (
    <div className={`${className} mb-lg-0`} ref={refWhy}>
      <Container>
        <Row>
          <Col>
            <div ref={refMain} className="h-100">
              <div
                className="overflow-hidden h-100 limit"
                css={css`
                  @media (max-width: 768px) {
                    right: -${mainGap}px;
                    width: ${mainWrapperWidth + BOXSPACING}px;
                  }
                `}
              >
                <div
                  className="h-100"
                  /*
                  css={css`
                    @media (min-width: 567px) and (max-width: 768px) {
                      width: ${mainWrapperWidth + EXTRAWIDTH}px;
                    }

                    @media (min-width: 567px) and (max-width: 768px) {
                      width: ${mainWrapperWidth + EXTRAWIDTH}px;
                    }
                  `}
                  */
                >
                  <Slider {...sliderSettings}>
                    {data.map((item, index) => (
                      <div className="slider-profile" key={index}>
                        <header>
                          <div className="avatar">
                            <div
                              className="round-image"
                              css={css`
                                background-image: url(${item.image.imageFile
                                  .publicURL});
                              `}
                            ></div>
                          </div>
                          <div className="text-wrapper">
                            <p className="name">{item.name}</p>
                            <p className="position">{item.position}</p>
                          </div>
                        </header>
                        <div className="body">
                          <h5 className="title">{item.title}</h5>
                          <p className="text">{item.text}</p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  margin-bottom: 88px;

  @keyframes pulse-black {
    0% {
      box-shadow: 0 0 0 0 rgba(109, 192, 114, 0.7);
      transform: scale(1);
    }

    75% {
      box-shadow: 0 0 0 15px rgba(109, 192, 114, 0);
      transform: scale(1);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(109, 192, 114, 0);
      transform: scale(1);
    }
  }

  position: relative;
  /*top: -16px;*/

  .limit {
    margin-left: -${BOXSPACING / 2}px;
    @media (min-width: 768px) {
      margin-left: -${BOXSPACING}px;
    }
  }

  .slider-profile {
    /*margin-left: ${BOXSPACING}px;
    margin-right: ${BOXSPACING / 2}px;
    margin-top: ${BOXSPACING * 1.5}px;*/
    padding: 12px 20px 20px;
    transition: all 100ms linear;
    width: initial !important;

    @media (min-width: 768px) {
      height: 365px;
    }

    @media (min-width: 992px) {
      height: 325px;
      margin-right: 0;
    }

    @media (min-width: 1200px) {
      height: 305px;
      margin-right: 0;
    }

    &:focus {
      outline: none;
    }

    header {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;

      .avatar {
        cursor: pointer;
        height: 71px;
        margin-right: 20px;
        position: relative;
        width: 71px;

        .round-image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 85px;
          border: 6px solid ${colors.saffron};
          border-radius: 50%;
          box-shadow: 0 0 0 0 ${colors.fern};
          height: 71px;
          padding: 0;
          position: absolute;
          transform: scale(1);
          transition: all 0.2s ease;
          width: 71px;
        }
      }

      .text-wrapper {
        @media (min-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        .name {
          color: ${colors.black};
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;

          @media (min-width: 768px) {
            margin-right: 58px;
          }
        }

        .position {
          color: ${colors.gray};
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      .avatar {
        .round-image {
          //animation: pulse-black 2s infinite;
          //border: 6px solid ${colors.fern};
        }
      }
    }

    .body {
      margin-top: 21px;
      padding: 0;

      @media (min-width: 768px) {
        margin-top: 19px;
      }

      .title {
        color: ${colors.black};
        font-size: 16px;
        font-weight: normal;
        margin-bottom: 20px;

        @media (min-width: 768px) {
          font-size: 20px;
          line-height: 32px;
        }
      }

      .text {
        color: ${colors.gray};
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  .slick-slider {
    .slick-dots {
      margin-bottom: 0;

      li, li button, li button:before{
        height: 10px;
        width: 10px;
      }
      li{
        button{
          &:before{
            font-size: 30px;
            line-height: 10px;
          }
        }
      }
    }
    .slick-list {
      position: relative;

      .slick-slide {
        margin-bottom: 48px;

        @media (min-width: 768px) {
          margin-bottom: ${BOXSPACING}px !important;
        }

        @media (min-width: 992px) {
          margin-right: 16px !important;
        }

        > div {
          background: transparent !important;
        }

        &.slick-current {
          .slider-profile {

            @media (min-width: 768px) {
            }

            .avatar {
              .round-image {
                animation: pulse-black 2s infinite;
                border: 6px solid ${colors.fern};
              }
            }
          }

          .text {
            color: ${colors.gray} !important;
          }
        }

        &:hover {
          .slider-profile {
          }
        }
      }
    }

    .slick-dots {
      margin-left: 15px;
    }

    .slick-arrow {
      bottom: -48px;
      transform: translateY(0%) !important;

      &.slick-prev {
        left: 32px;
      }

      &.slick-next {
        left: 88px;
      }
    }
  }
`

Testimonials.propTypes = {
  data: array.isRequired
}

Testimonials.defaultProps = {
  data: []
}

export default Testimonials
