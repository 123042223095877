import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const ShapeYellowRightSimple = styled.div`
  background-color: ${colors.saffron};
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  content: "";
  height: 100%;
  position: absolute;
  right: 0;

  @media (min-width: 768px) {
    display: block;
    height: 490px;
    width: 505px;
  }

  @media (min-width: 1440px) {
    display: block;
    height: 711px;
    width: 934px;
  }

  @media (max-width: 767px) {
    width: 200%;
  }
`
export default ShapeYellowRightSimple
