import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Background from 'gatsby-background-image'
import { Link } from 'gatsby'

import Hr from '@components/Hr'

import ServicesArrow from '@images/services-arrow.svg'

const ServicesBlock = styled(({ section, className }) => (
  <div className={`${className} position-relative`}>
    <Container>
      <Row className="mx-0 align-items-center">
        <Col xs={11} md={10} lg={8} xl={7} className="p-0">
          <div className="h2">{section.title}</div>
          <Link to="/work" className="h6">
            <span>{section.subtitle}</span>
            <ServicesArrow />
          </Link>
        </Col>
        {section.servicesList.map((item, index) => {
          const { image, title, url } = item
          return (
            <Col
              xl={6}
              md={6}
              className={`p-m-0 ${index % 2 == 0 ? 'pl-md-0' : 'pr-md-0'}`}
              key={index}
            >
              <Link to={url} className="services-item">
                {/*
                <Background
                  className={`image`}
                  Tag="section"
                  fluid={image.imageFile.childImageSharp.fluid}
                  alt={image.altText ? image.altText : title}
                />
                */}
                <div className="image-wrapper">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(${image.imageFile.childImageSharp.fluid.srcWebp})`
                    }}
                  ></div>
                </div>
                <div className="title">
                  <span>{title}</span>
                  <Hr />
                </div>
              </Link>
            </Col>
          )
        })}
        <Col xl={12} className="p-xl-0 text-center">
          <Link to={section.button.buttonUrl} className="all-services">
            {section.button.buttonTitle}
          </Link>
        </Col>
      </Row>
    </Container>
  </div>
))`
  padding-bottom: 96px;

  @media (min-width: 768px) {
    padding-bottom: 120px;
  }

  @media (max-width: 767px) {
    .p-m-0 {
      padding: 0;
    }
  }

  .h2 {
    color: #000;
    font-weight: 300;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 64px;
      line-height: 75px;
    }
  }

  .h6 {
    color: #000;
    display: block;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 96px;

    @media (min-width: 768px) {
      margin-bottom: 56px;
    }

    svg {
      margin-left: 30px;
      transition: all 250ms linear;
    }

    &:hover {
      text-decoration: none;
      svg {
        path {
          fill: #2b58ca;
        }
      }
    }
  }

  .services-item {
    display: block;
    position: relative;
    margin-bottom: 74px;

    &:hover {
      .image {
        -webkit-transform: scale(1.08);
        transform: scale(1.08);
      }
      .title {
        hr {
          background-color: #2b58ca;
        }
      }
    }

    .image-wrapper {
      display: block;
      overflow: hidden;

      .image {
        height: 330px;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;

        @media (min-width: 1440px) {
          height: 430px;
        }
      }
    }

    .title {
      background-color: #fff;
      bottom: -44px;
      color: #000000;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      left: 0;
      padding: 24px 40px 24px 24px;
      position: absolute;
      width: calc(100% - 24px);

      @media (min-width: 1440px) {
        width: calc(100% - 120px);
      }

      hr {
        background: #f5c141;
        height: 3px;
        margin-top: 24px;
        -webkit-transition: 0.4s ease;
        transition: 0.4s ease;
      }
    }
  }

  .all-services {
    background: #f5c141;
    color: #000000;
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 48px;
    text-transform: uppercase;
    transition: all 250ms linear;

    @media (min-width: 768px) {
      display: inline-block;
    }

    &:hover {
      background: #2b58ca;
      color: #fff;
      text-decoration: none;
    }
  }
`

ServicesBlock.propTypes = {
  section: object.isRequired
}

ServicesBlock.defaultProps = {
  section: {}
}

export default ServicesBlock
