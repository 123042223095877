import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import SEO from '@components/SEO'
import DescribeHome from '@components/DescribeHome'
// import ContactForm from '@components/ContactForm'
import Hr from '@components/Hr'
import HeroBanner from './components/HeroBanner'
import ServicesBlock from './components/ServicesBlock'
//import ShapeYellowTonesRight from './components/ShapeYellowTonesRight'
//import Carousel from './components/Carousel'
//import CarouselBubble from './components/CarouselBubble'
import CarouselProfile from './components/CarouselProfile'
import Testimonials from './components/Testimonials'
import Newsletter from './components/Newsletter'
import ProjectForm from './components/ProjectForm'
import ShapeYellowRightSimple from './components/ShapeYellowRightSimple'

// graphql
import { homeContent } from './modules/graphql'

const Index = styled(({ className }) => {
  const seoData = homeContent()[0]
  const heroData = homeContent()[1]
  const introData = homeContent()[2]
  const servicesList = homeContent()[3]
  const carouselProfileData = homeContent()[4]
  const getInTouchData = homeContent()[5]
  const newsletterData = homeContent()[6]
  const testimonialsData = homeContent()[7]
  const projectFormData = homeContent()[8]

  return (
    <div className={`${className}`}>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keyword={seoData.keyword}
      />
      <div className="page-wrapper">
        <div className="hero-wrapper">
          <Img
            className="page-wrapper-background d-none d-lg-block"
            fluid={heroData.desktopheroimg.imageFile.childImageSharp.fluid}
            alt={
              heroData.desktopheroimg.altText
                ? heroData.desktopheroimg.altText
                : heroData.box.title
            }
          />
          <Img
            className="page-wrapper-background d-none d-md-block d-lg-none"
            fluid={heroData.tabletheroimg.imageFile.childImageSharp.fluid}
            alt={
              heroData.tabletheroimg.altText
                ? heroData.tabletheroimg.altText
                : heroData.box.title
            }
          />
          <Img
            className="page-wrapper-background d-md-none"
            fluid={heroData.mobileheroimg.imageFile.childImageSharp.fluid}
            alt={
              heroData.tabletheroimg.altText
                ? heroData.tabletheroimg.altText
                : heroData.box.title
            }
          />
          {/* HERO */}
          <HeroBanner section={heroData} />
        </div>
        <div className="hero-intro">
          <Container>
            <Row>
              <Col xl={6} md={8}>
                <div className="h3">{introData.title}</div>
                <Hr />
                <p>{introData.description}</p>
              </Col>
            </Row>
          </Container>
        </div>
        <ServicesBlock section={servicesList} />
        {/*
        <div className="position-relative">
          <ShapeYellowTonesRight className="position-absolute w-100" />
        </div>
        */}
        {/* Why Codistry */}
        {/*
        <section className="my-md-3 d-inline-block w-100">
          <div className="mb-5">
            <Container>
              <Row>
                <Col>
                  <h2 className="mb-3 mt-5">{carouselData.title}</h2>
                  <Hr className="mb-5" />
                </Col>
              </Row>
            </Container>
            <Carousel clasName="d-flex" data={carouselData.carousel} />
          </div>
        </section>
        */}
        {/* We make impossible ideas, reality */}
        {/*
        <div className="max-width-body">
          <section className="my-md-3 d-inline-block w-100">
            <Container className="d-xl-none">
              <Row>
                <Col>
                  <h2>{carouselBubbleData.title}</h2>
                  <Hr className="mb-5" />
                </Col>
              </Row>
            </Container>
            {
              <CarouselBubble
                className="mb-5"
                content={carouselBubbleData.carouselbubble}
              />
            }
          </section>
        </div>
        */}
        {/* Our Team */}
        <section>
          {/*
          <div className="bg-white">
            <Container className="mt-md-3">
              <Row>
                <Col>
                  <h2>{carouselProfileData.title}</h2>
                  <Hr className="mb-5" />
                </Col>
              </Row>
            </Container>
          </div>
          */}
          <div className="bg-alabaster">
            <div className="max-width-body">
              {/* Our Team continue */}
              <div className="pb-0 pb-md-5">
                <CarouselProfile data={carouselProfileData} />
              </div>
            </div>
          </div>
        </section>
        {/* Testimonials */}
        <div className="bg-alabaster testimonials">
          <section className="d-inline-block w-100">
            <div className="mb-md-5">
              <Container>
                <Row>
                  <Col>
                    <h2 className="pr-5">{testimonialsData.title}</h2>
                    <Hr className="mb-5" />
                  </Col>
                </Row>
              </Container>
              <Testimonials data={testimonialsData.testimonial} />
            </div>
          </section>
        </div>
        <div className="bg-alabaster">
          {/* Get in touch with us
          <div className="pt-md-3 d-inline-block w-100">
            <Container>
              <Row>
                <Col>
                  <h2 className="mb-3">{getInTouchData.title}</h2>
                  <Hr className="mb-5" />
                </Col>
              </Row>
            </Container>
          </div>
          */}
          <div className="bg-cerulean-blue">
            <section
              className="max-width-body describe-home"
              style={{
                backgroundImage:
                  'url(' + getInTouchData.worldimg.sourceUrl + ')'
              }}
            >
              <div>
                <Container>
                  <Row>
                    <Col className="col-12">
                      <span className="h4">{getInTouchData.title}</span>
                      <Hr />
                    </Col>
                    <Col xl={7}>
                      <span className="h5">Describe your project</span>
                      <p>We'll get back to you in 24 hours</p>
                    </Col>
                    <Col xl={5}>
                      {/* Form  */}
                      <DescribeHome />
                      {/* <ContactForm /> */}
                    </Col>
                  </Row>
                </Container>
              </div>
            </section>
          </div>
        </div>
        <div className="position-relative">
          <ShapeYellowRightSimple />
          <div className="max-width-body">
            {/* Newsletter */}
            <div className="newsletter-wrapper">
              <div className="d-inline-block w-100">
                <Newsletter data={newsletterData} />
              </div>
            </div>
          </div>
        </div>
        {/*
        <div className="project-form">
          <Container>
            <Row>
              <Col md="6">
                <div
                  className="project-form-image d-md-none"
                  style={{
                    backgroundImage:
                      'url(' + projectFormData.image.mobile.sourceUrl + ')'
                  }}
                ></div>
                <div
                  className="project-form-image d-none d-md-block d-xl-none"
                  style={{
                    backgroundImage:
                      'url(' + projectFormData.image.tablet.sourceUrl + ')'
                  }}
                ></div>
                <div
                  className="project-form-image d-none d-xl-block"
                  style={{
                    backgroundImage:
                      'url(' + projectFormData.image.desktop.sourceUrl + ')'
                  }}
                ></div>
              </Col>
              <Col md="6">
                <h6>
                  <span>{projectFormData.title}</span>
                </h6>
                <ProjectForm submit="Let’s chat" />
              </Col>
            </Row>
          </Container>
        </div>
        */}
      </div>
    </div>
  )
})`
  .page-wrapper {
    position: relative;
    //overflow: hidden;

    .hero-wrapper {
      background: #2b58ca;
    }

    .page-wrapper-background {
      background-color: #e5e5e5;
      height: min-content;
      position: absolute !important;
      overflow: visible !important;
      top: 0;
      width: 100%;
      z-index: 0;

      * {
        z-index: 0;
      }

      &:before {
        background-color: transparent;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        content: '';
        height: 101%;
        position: absolute;
        width: 101%;
        z-index: 1;

        @media (min-width: 768px) {
          background-color: #fff;
        }
      }
    }
  }

  .hero-intro {
    padding: 80px 0 96px;
    position: relative;

    @media (max-width: 767px) {
      background: #fff;
    }

    @media (min-width: 768px) {
      padding: 260px 0 96px;
    }

    @media (min-width: 1440px) {
      padding: 145px 0 100px;
    }

    .h3 {
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 47px;
      }
    }

    hr {
      height: 4px;
      margin-bottom: 40px;
    }

    p {
      color: #767676;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.03em;
    }
  }

  .describe-home {
    background-repeat: no-repeat;
    padding-bottom: 80px;
    padding-top: 80px;

    @media (max-width: 767px) {
      background-image: none !important;
    }

    @media (min-width: 768px) {
      background-position: 80% 55%;
      background-size: 125%;
    }

    @media (min-width: 992px) {
      background-position: center center;
      background-size: 100%;
      background-position: 13% 55%;
    }

    @media (min-width: 1200px) {
      background-position: 13% 75%;
      background-size: 65%;
    }

    @media (min-width: 1440px) {
      background-position: 40% 74%;
      background-size: 85%;
      padding-bottom: 100px;
    }

    .h4 {
      color: #fff;
      display: block;
      font-weight: bold;
      font-size: 32px;
      letter-spacing: 0.02em;
      line-height: 37px;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 47px;
      }
    }

    hr {
      height: 4px;
      margin-bottom: 23px;
    }

    .h5 {
      color: #fff;
      display: block;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 16px;
    }

    p {
      color: #fff;
      display: block;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 41px;

      @media (min-width: 1440px) {
        margin-bottom: 0;
      }
    }

    form {
      .form-group {
        label {
          font-size: 20px;
        }

        input {
          height: 60px;
        }

        .inquiry-select {
          input {
            height: inherit;
          }
        }

        [class*='-control'] {
          border-color: hsl(0, 0%, 70%) !important;
          box-shadow: none !important;
        }

        input,
        select,
        textarea {
          &.error {
            ::placeholder {
              color: red;
            }

            :-ms-input-placeholder {
              color: red;
            }

            ::-ms-input-placeholder {
              color: red;
            }
          }
        }

        p.invalid-helper {
          display: none;
          font-size: 16px !important;
          margin-bottom: 10px;

          &::first-letter {
            text-transform: uppercase;
          }
        }

        button {
          padding: 16px 48px;
          margin-top: 32px;
          text-transform: uppercase;
        }

        .form-select {
          position: relative;

          .dropdown-icon {
            background-size: cover;
            display: block;
            height: 12px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            z-index: 1;
            width: 18px;
          }
        }

        select.is-valid + svg {
          display: none !important;
        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: '';
          position: relative;

          &::-ms-expand {
            display: none;
          }

          &:active,
          &:hover {
            outline-color: red;
          }
        }
      }
    }
  }

  .testimonials {
    padding-top: 96px;

    h2 {
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      letter-spacing: 0.02em;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 47px;
      }

      @media (min-width: 1440px) {
        font-size: 40px;
        line-height: 47px;
      }
    }

    hr {
      height: 4px;
    }
  }

  .newsletter-wrapper {
    margin-bottom: 56px;

    @media (min-width: 768px) {
      margin-bottom: 80px;
    }
    @media (min-width: 1440px) {
      margin-bottom: 130px;
    }
  }

  .project-form {
    margin-bottom: 80px;

    .project-form-image {
      background-size: cover;
      background-position: center top;
      display: block;
      height: 186px;
      position: relative;
      margin-bottom: 31px;

      @media (min-width: 768px) {
        height: 392px;
        margin-bottom: 0;
        margin-top: 35px;
        margin-left: auto;
        width: calc(100% - 35px);
      }

      @media (min-width: 1440px) {
        height: 280px;
      }

      &:before {
        background: #e2f2e2;
        content: '';
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        display: block;
        height: 173px;
        top: -24px;
        left: -24px;
        position: absolute;
        z-index: -1;
        width: 173px;

        @media (min-width: 768px) {
          height: 324px;
          top: -35px;
          left: -35px;
          width: 324px;
        }

        @media (min-width: 1440px) {
          height: 324px;
          top: -35px;
          left: -35px;
          width: 324px;
        }
      }
    }

    input {
      border: 1px solid #c5c5c5;
      margin-bottom: 16px;

      &.is-valid {
        background: none !important;
      }

      &.error {
        border: 1px solid #e10506;
        margin-bottom: 8px;
      }
    }

    button {
      background: #f5c141;
      color: #000;
      display: block;
      text-transform: uppercase;
      margin-top: 16px;
      width: 100%;

      @media (min-width: 1440px) {
        margin-top: 0;
      }
    }

    .invalid-helper {
      color: #e10506;
      font-size: 16px;
      line-height: 24px;
      padding-left: 24px;
      margin-bottom: 16px;
      margin-top: 0;
    }

    h6 {
      align-items: center;
      color: #000000;
      display: flex;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.03em;

      @media (min-width: 1440px) {
        font-size: 24px;
        line-height: 36px;
        margin-top: 35px;
      }

      &:after {
        background: #6dbf70;
        content: '';
        display: inline-block;
        height: 2px;
        margin-left: 16px;
        width: 50px;
      }
    }
  }
`

export default Index
