import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import Hr from '@components/Hr'
import Form from './components/Form'

import colors from '@styles/js/colors'

const Newsletter = styled(({ data, className }) => (
  <div className={className}>
    <div id="subscribe"></div>
    <Container>
      <Row className="align-items-center">
        <Col xs={12} md={7} lg={5} className="mb-xl-5 mb-3 mb-lg-0">
          <div className="title">
            <h3 className="mb-3">{data.title}</h3>
            <Hr color="#6dc072" className="mb-3" />
            <p className="disclaimer m-0 pr-4 mr-3">{data.text}</p>
          </div>
        </Col>
        <Col xs={12} lg={7}>
          <div className="form-wrapper bg-white w-100 p-5">
            <Form
              label={data.label}
              inputPlaceholder={data.inputplaceholder}
              buttonText={data.buttontext}
            />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
))`
  padding-top: 60px;

  @media (min-width: 768px) {
    padding-top: 109px;
  }

  @media (min-width: 1440px) {
    padding-top: 170px;
  }

  #subscribe {
    position: absolute;
    top: -130px;
  }

  .title {
    color: ${colors.white} !important;

    @media (min-width: 768px) {
      color: ${colors.black} !important;
    }

    h3 {
      color: #000000;
      font-size: 32px;
      line-height: 37px;

      @media (min-width: 768px) {
        font-size: 40px;
        line-height: 47px;
      }
    }

    hr {
      height: 4px;
      margin-bottom: 24px !important;
      margin-top: 40px;

      @media (max-width: 767px) {
        background-color: #000;
      }
    }

    .disclaimer {
      color: ${colors.gray} !important;
      font-size: 20px;
      line-height: 32px;

      @media (max-width: 767.98px) {
        color: #000 !important;
        margin-bottom: 32px !important;
      }
    }
  }

  .form-wrapper {
    box-shadow: 0px 25px 40px -10px rgba(112, 112, 112, 0.05);
    max-width: 782px;

    @media (max-width: 767.98px) {
      padding: 32px 20px !important;
    }

    label {
      font-size: 20px;
      line-height: 32px;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 36px;
      }
    }

    input {
      height: 60px !important;

      &:focus {
        outline: none;
      }
    }

    .invalid-helper {
      color: #e10506;
      font-size: 16px;
      line-height: 24px;
      padding-left: 24px;
      margin-bottom: 16px;
      margin-top: 0;
    }

    button {
      background: #2b58ca;
      color: #fff;
      height: 60px !important;
      text-transform: uppercase;
      width: 100%;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: #f5c141;
      }

      &.error {
        background-color: #f44336;
      }
    }
  }
`

Newsletter.propTypes = {
  data: object.isRequired
}

Newsletter.defaultProps = {
  data: {}
}

export default Newsletter
