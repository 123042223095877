import styled from "@emotion/styled"

const Triangle = styled.div`
  background: linear-gradient(
    to top right,
    transparent 0%,
    transparent 50%,
    #c06dbb 50%,
    #c06dbb 99%
  );
  height: 259px;
  max-width: 262px;
  position: absolute;
  right: -24px;
  top: -24px;
  width: 100%;

  @media (min-width: 768px) {
    height: 329px;
    max-width: 325px;
  }

  @media (min-width: 1440px) {
    height: 614px;
    right: -30px;
    top: -30px;
    max-width: 622px;
  }
`

export default Triangle
