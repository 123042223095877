import React from 'react'
//import ButtonPrev from '@components/ButtonPrev'
//import ButtonNext from '@components/ButtonNext'

const sliderSettings = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.3,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 568,
      settings: {
        slidesToShow: 1.18,
        arrows: false,
        dots: true
      }
    }
  ]
}

export default sliderSettings
