import React, { useState, useEffect } from 'react'
import { object, number, string } from 'prop-types'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/core'
import { pulse } from 'react-animations'
import BackgroundImage from 'gatsby-background-image'

// utils
import colors from '@styles/js/colors'

const BackgroundSection = ({ alt, fluid, position, className }) => {
  const [didChange, setDidChange] = useState(false)
  const transitionTime = 500
  const pulseAnimation = keyframes`${pulse}`

  useEffect(() => {
    setDidChange(true)

    const timer = setTimeout(() => {
      setDidChange(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [position])

  return (
    <div
      className={`h-100 w-100`}
      css={
        didChange
          ? css`
              animation: ${transitionTime}ms ${pulseAnimation};
            `
          : ''
      }
    >
      <BackgroundImage
        Tag="section"
        className={`${className} h-100 w-100`}
        fluid={fluid}
        alt={alt}
        backgroundColor={colors.saffron}
      />
    </div>
  )
}

BackgroundSection.propTypes = {
  fluid: object,
  position: number,
  className: string,
  alt: string
}

BackgroundSection.defaultProps = {
  fluid: {},
  position: 0,
  className: '',
  alt: ''
}

const Image = styled(BackgroundSection)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export default Image
