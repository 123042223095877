import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Home from '@compositions/Home'

const BlogPage = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}

export default BlogPage
