import { useStaticQuery, graphql } from 'gatsby'

export const homeContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "21", idType: DATABASE_ID) {
          homeContent {
            homecontent {
              __typename
              ... on WPGraphQL_Page_Homecontent_Homecontent_Seodata {
                title
                description
                keyword
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Herodata {
                box {
                  title
                  text
                  subtitle
                  buttonbig {
                    text
                    url
                    textcolor
                    backgroundcolor
                  }
                }
                mobileheroimg {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1050, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                tabletheroimg {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1050, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                desktopheroimg {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1050, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Intro {
                description
                title
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Services {
                fieldGroupName
                subtitle
                title
                button {
                  buttonTitle
                  buttonUrl
                }
                servicesList {
                  url
                  title
                  image {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 725, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Carouselprofiledata {
                title
                heading
                carouselprofile {
                  name
                  position
                  image {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 725, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  alt
                }
                meetteam
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Getintouchdata {
                title
                worldimg {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 900, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Newsletter {
                title
                text
                label
                inputplaceholder
                buttontext
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Testimonialsdata {
                title
                testimonial {
                  image {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      publicURL
                    }
                  }
                  name
                  position
                  title
                  text
                }
              }
              ... on WPGraphQL_Page_Homecontent_Homecontent_Projectformdata {
                fieldGroupName
                submitbutton
                title
                image {
                  mobile {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      publicURL
                    }
                  }
                  tablet {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      publicURL
                    }
                  }
                  desktop {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return [
    data.wpgraphql.page.homeContent.homecontent[0],
    data.wpgraphql.page.homeContent.homecontent[1],
    data.wpgraphql.page.homeContent.homecontent[2],
    data.wpgraphql.page.homeContent.homecontent[3],
    data.wpgraphql.page.homeContent.homecontent[4],
    data.wpgraphql.page.homeContent.homecontent[5],
    data.wpgraphql.page.homeContent.homecontent[6],
    data.wpgraphql.page.homeContent.homecontent[7],
    data.wpgraphql.page.homeContent.homecontent[8]
  ]
}
