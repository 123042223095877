import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { string } from 'prop-types'
import Alert from 'react-bootstrap/Alert'
import Cookies from 'js-cookie'
import axios from 'axios'
import publicIp from 'public-ip'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import Input from './components/Input'
import Button from './components/Button'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const PORTAL_ID = 5217373
const FORM_ID = '74f60aee-d0a7-43b3-8f65-eee22d208725'

const schema = Yup.object({
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email is required')
})

const SUCCESS = 'success'
const INFO = 'info'
const ERROR = 'danger'
let tmr = null

const Form = ({ label, inputPlaceholder, buttonText }) => {
  const [ipAddress, setIpAddress] = useState('')
  const [message, setMessage] = useState({
    type: SUCCESS,
    text: '',
    show: false
  })

  const getClientIp = async () => {
    const ip = await publicIp.v4()
    setIpAddress(ip)
  }

  useEffect(() => {
    getClientIp()
  }, [ipAddress])

  useEffect(() => {
    return () => hideMessage(tmr)
  }, [])
  function hideMessage() {
    clearTimeout(tmr)
    setMessage({
      show: false
    })
  }
  function showMessage(text, type = INFO) {
    setMessage({
      type,
      text,
      show: true
    })
    tmr = setTimeout(() => {
      hideMessage()
    }, 10000)
  }
  const handleSubmit = (model, { setSubmitting, resetForm }) => {
    showMessage('Subscribing email...')

    const { email } = model
    const isBrowser = typeof window !== 'undefined'
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`

    const body = {
      fields: [
        {
          name: 'email',
          value: email
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
        ipAddress
      }
    }

    axios
      .post(postUrl, body)
      .then(res => {
        if (res.status === 200) {
          resetForm()
          showMessage('You subscribe successfully!')
        }
      })
      .catch(err => {
        setSubmitting(false)
        if (err) {
          showMessage(
            'Sorry, but something went wrong. Please try again',
            ERROR
          )
          return console.error(err)
        }
      })
  }
  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        email: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors
      }) => (
        <form onSubmit={handleSubmit} className="mt-0 d-block">
          <Row className="justify-content-center justify-content-xl-start row">
            <Col className="col-12 col-md-10 p-md-0 text-center text-lg-left">
              <label className="text-black mb-4" htmlFor="newsletter-email">
                {label}
              </label>
            </Col>
            <Col md={8} className="p-md-0">
              <div className="form-group">
                <Input
                  type="email"
                  name="email"
                  id="newsletter-email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.email && !errors.email}
                  value={values.email}
                />
              </div>
              {errors.email && touched.email ? (
                <p className="invalid-helper">{errors.email}</p>
              ) : null}
            </Col>
            <Col md={4} className="p-md-0">
              <Button
                type="submit"
                className={errors.email && touched.email ? 'error' : null}
              >
                {buttonText}
              </Button>
            </Col>
            <Col>
              {message.show && (
                <div className="form-group">
                  <br />
                  <Alert variant={message.type}>{message.text}</Alert>
                </div>
              )}
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  )
}

Form.propTypes = {
  label: string.isRequired,
  buttonText: string.isRequired,
  inputPlaceholder: string.isRequired
}

Form.defaultProps = {
  label: '',
  buttonText: '',
  inputPlaceholder: ''
}

export default Form
