import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import ButtonBig from '@components/ButtonBig'

const HeroBanner = styled(({ section, className }) => (
  <div className={`${className} position-relative`}>
    <div className="shapes">
      <div className="shape"></div>
      <div className="shape"></div>
    </div>
    <Container>
      <Row className="mx-0 align-items-center">
        <Col lg={6} className="pl-0 pr-0 pr-md-0 pr-xl-0">
          <Container>
            <Row>
              <Col md={8} lg={12} className="pl-0 pr-2 pr-xl-0 pr-md-2">
                <div className="inner-block">
                  <h6>{section.box.subtitle}</h6>
                  <h1 className="text-white">{section.box.title}</h1>
                  <p className="text-white">{section.box.text}</p>
                  <ButtonBig
                    text={section.box.buttonbig.text}
                    url={section.box.buttonbig.url}
                    textColor={section.box.buttonbig.textColor}
                    backgroundColor={section.box.buttonbig.backgroundColor}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        {/*
            <Col lg={7} className="px-0">
              <Hero
                img={heroData.heroimg.imageFile.childImageSharp.fluid}
                alt={
                  heroData.heroimg.altText
                    ? heroData.heroimg.altText
                    : 'Homepage Hero Image'
                }
              />
            </Col>
            */}
      </Row>
    </Container>
  </div>
))`
  overflow: hidden;

  .shapes {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;

    div {
      background: #2b58ca;
    }

    @media (max-width: 767px) {
      top: 103px;

      div {
        width: 100%;
      }

      > div:first-of-type {
        height: 390px;
        order: 1;
      }

      > div:last-of-type {
        bottom: -1px;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        height: 250px;
        position: relative;
      }
    }

    @media (min-width: 768px) {
      height: 100%;

      > div:first-of-type {
        flex: 0 0 35%;
        max-width: 35%;
      }

      > div:last-of-type {
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        flex: 0 0 65%;
        left: -1px;
        position: relative;
        max-width: 65%;
      }
    }

    @media (min-width: 1200px) {
      > div:first-of-type {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      > div:last-of-type {
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }
    }
  }

  .inner-block {
    padding: 295px 7px 47px 8px;

    @media (min-width: 768px) {
      padding: 148px 0 64px;
    }

    @media (min-width: 996px) {
      padding: 100px 0;
    }

    h6 {
      color: #fff;
      font-size: 18px;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    h1 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      letter-spacing: 0.02em;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        padding-right: 3rem;
      }

      @media (min-width: 992px) {
        font-size: 54px;
        line-height: 63px;
        padding-right: 1rem;
      }
    }

    p {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 40px;
      padding-right: 17px;

      @media (min-width: 768px) {
        padding-right: 0;
      }
    }

    a {
      height: auto !important;
      padding: 16px 48px !important;
      text-transform: uppercase;
      transition: all 250ms linear;

      &:hover {
        text-decoration: none;
      }
    }
  }
`

HeroBanner.propTypes = {
  section: object.isRequired
}

HeroBanner.defaultProps = {
  section: {}
}

export default HeroBanner
