import React from 'react'
import { object, func, number } from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// utils
import colors from '@styles/js/colors'

import ServicesArrow from '@images/services-arrow.svg'

const Content = styled(({ data, position, onClick, className }) => {
  return (
    <div className={`${className}`}>
      <Container className="h-100">
        <Row className="justify-content-md-center h-100">
          <Col md={12} className="p-xl-0">
            <h4 className="heading d-none d-lg-block">{data.heading}</h4>
            <div className="">
              {data.carouselprofile.map((item, index) => (
                <button
                  className={`${
                    index === position ? 'active' : ''
                    } d-flex justify-content-between w-100 bg-transparent member`}
                  onClick={() => onClick(index)}
                  key={index}
                >
                  <span className="font-weight-bold member-name">
                    {item.name}
                  </span>
                  <span className="text-capitalize mb-0 member-position">
                    {item.position}
                  </span>
                </button>
              ))}
            </div>
            <div className="d-flex flex-column justify-content-between mb-0 position-relative">
              <Link className="mb-1 w-100 team-link" to="/team">
                {data.meetteam}
                <ServicesArrow/>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  padding-bottom: 56px;

  @media (min-width: 768px) {
    padding-bottom: 0;
    margin-top: -70px;
  }

  @media (min-width: 800px) {
    margin-left: 30px;
    width: calc(50% + 40px);
  }

  @media (min-width: 992px) {
    padding-left: 20px;
    padding-right: 51px;
    padding-top: 56px;
  }

  @media (min-width: 1200px) {
    padding-left: 30px;
    padding-right: 151px;
    padding-top: 56px;
  }

  .heading {
    color: #fff;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.03em;
    margin-bottom: 24px;
    transition: all 250ms linear;

    &.active {
      display: block;
      opacity: 1;
    }
  }

  .member {
    border: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    flex-direction: column;
    opacity: 0.7;
    padding-left: 0;
    transition: all 250ms linear;
    padding-top: 0;
    padding-bottom: 9px;
    margin-bottom: 30px;

    &:last-of-type{
      margin-bottom: 0;
    }

    &:focus {
      outline: 0;
    }

    span{
      &:first-of-type {
        margin-bottom: 9px;
      }
    }

    &:hover,
    &.active {
      border-color: #F5C141;
      opacity: 1;

      span {
        &:first-of-type {
          color: ${colors.white};
        }
      }
    }

    span {
      color: ${colors.white};
      text-align: left;
      transition: all 250ms linear;

      &.member-name{
        font-size: 20px;
      }

      &.member-position{
        font-size: 18px;
      }
    }
  }

  .team {
    color: ${colors.white};
    font-size: 20px;
    padding-right: 40%;
    transition: all 300ms linear;

    &:hover {
      color: ${colors.black};
      border-bottom: 2px solid ${colors.black};
      text-decoration: none;
    }

    &.the-team {
      &:hover {
        color: ${colors.white};
        border-bottom: 0;
      }
    }

    &:hover {
      border: 0;
    }
  }

  .team-link{
    color: #FFFFFF;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.03em;
    margin-top: 40px;

    svg{
      margin-left: 30px;
    }
  }
`

Content.propTypes = {
  data: object.isRequired,
  position: number,
  onClick: func
}

Content.defaultProps = {
  data: {},
  position: 0,
  onClick: () => null
}

export default Content
