import styled from '@emotion/styled'

import colors from '@styles/js/colors'

const Input = styled.input`
  background-clip: padding-box;
  background-color: ${colors.white};
  border: 1px solid ${colors.mercury};
  border-radius: 0;
  font-size: 0.875rem;
  height: calc(1.5em + 2rem + 2px);
  line-height: 1.5;
  padding: 1rem 1.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
`

export default Input
