import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const Button = styled.button`
  background-color: ${colors.saffron};
  border: 0;
  color: ${colors.black};
  font-size: 18px !important;
  height: 58px !important;
  padding: 0.85rem 20px;
  transition: all 250ms linear;

  @media (max-width: 767.98px) {
    width: 100%;
  }

  &:hover {
    background-color: ${colors.gamboge};
    color: ${colors.white};
  }
`

export default Button
