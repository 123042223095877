import React, { useState, useEffect } from 'react'
import { string } from 'prop-types'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Cookies from 'js-cookie'
import axios from 'axios'
import publicIp from 'public-ip'
import ReCAPTCHA from 'react-google-recaptcha'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// components
import StyledButton from './components/Button'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Content-Type'] = 'application/json'

const PORTAL_ID = 5217373
const FORM_ID = '696da0f0-a69f-41c5-92b6-75e7011440e4'

const schema = Yup.object({
  firstname: Yup.string().required('Name is required'),
  company: Yup.string().required('This field is mandatory'),
  email: Yup.string()
    .email('Enter a valid email address')
    .required('Email is required')
})

const SUCCESS = 'success'
const INFO = 'info'
const ERROR = 'danger'
let tmr = null

const ProjectForm = ({ submit }) => {
  const [recaptcha, setRecaptcha] = useState(false)
  const [ipAddress, setIpAddress] = useState('')

  const [message, setMessage] = useState({
    type: SUCCESS,
    text: '',
    show: false
  })

  const getClientIp = async () => {
    const ip = await publicIp.v4()
    setIpAddress(ip)
  }

  useEffect(() => {
    getClientIp()
  }, [ipAddress])

  useEffect(() => {
    return () => hideMessage(tmr)
  }, [])

  function hideMessage() {
    clearTimeout(tmr)
    setMessage({
      show: false
    })
  }
  function showMessage(text, type = INFO) {
    setMessage({
      type,
      text,
      show: true
    })
    tmr = setTimeout(() => {
      hideMessage()
    }, 10000)
  }

  const handleSubmit = (model, { setSubmitting, resetForm }) => {
    showMessage('Sending message...')

    const { firstname, company, email } = model

    const isBrowser = typeof window !== 'undefined'
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`

    const body = {
      fields: [
        {
          name: 'firstname',
          value: firstname
        },
        {
          name: 'your_company',
          value: company
        },
        {
          name: 'email',
          value: email
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
        ipAddress
      }
    }

    axios
      .post(postUrl, body)
      .then(res => {
        if (res.status === 200) {
          resetForm()
          showMessage('Message successfully sent!')
        }
      })
      .catch(err => {
        setSubmitting(false)
        if (err) {
          showMessage(
            'Sorry, but something went wrong. Please try again',
            ERROR
          )
          return console.error(err)
        }
      })
  }

  function verifyCallback(value) {
    if (value != '') {
      setRecaptcha(true)
    }
  }

  function onExpired() {
    setRecaptcha(false)
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        firstname: '',
        company: '',
        email: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        isSubmitting,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col xl="6" className="pr-xl-2">
              <Form.Group>
                <Form.Control
                  type="text"
                  name="firstname"
                  id="hubspot-form-name"
                  value={values.firstname}
                  onChange={handleChange}
                  placeholder="Enter your Name"
                  isValid={touched.firstname && !errors.firstname}
                  className={
                    errors.firstname && touched.firstname ? 'error' : ''
                  }
                />
                {errors.firstname && touched.firstname ? (
                  <p className="invalid-helper">{errors.firstname}</p>
                ) : null}
              </Form.Group>
            </Col>
            <Col xl="6" className="pl-xl-2">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Enter your Email"
                  name="email"
                  id="hubspot-form-email"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                  className={errors.email && touched.email ? 'error' : ''}
                />
                {errors.email && touched.email ? (
                  <p className="invalid-helper">{errors.email}</p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Control
              type="text"
              name="company"
              id="hubspot-form-company"
              value={values.company}
              onChange={handleChange}
              placeholder="Enter your Company Name"
              isValid={touched.company && !errors.company}
              className={errors.company && touched.company ? 'error' : ''}
            />
            {errors.company && touched.company ? (
              <p className="invalid-helper">{errors.company}</p>
            ) : null}
          </Form.Group>
          <Row className="align-items-center">
            <Col xl="7">
              <ReCAPTCHA
                sitekey="6LdW6WQbAAAAAG_t7gfCkO8QJZHLqVw4G5DmQ2Tc"
                onChange={verifyCallback}
                onExpired={onExpired}
                className="ReCAPTCHA"
              />
            </Col>
            <Col xl="5">
              <Form.Group className="mb-0 mt-0">
                {/* <StyledButton disabled={!isValid || isSubmitting} type="submit"> */}
                <StyledButton
                  type="submit"
                  disabled={recaptcha === false ? true : false}
                  className={recaptcha === false ? 'disabled' : null}
                >
                  {isSubmitting ? 'Sending...' : submit}
                </StyledButton>
              </Form.Group>
            </Col>
            <Col className="mt-3">
              {message.show && (
                <Form.Group>
                  <Alert variant={message.type}>{message.text}</Alert>
                </Form.Group>
              )}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

ProjectForm.propTypes = {
  submit: string.isRequired
}

ProjectForm.defaultProps = {
  submit: {}
}

export default ProjectForm
